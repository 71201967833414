import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import './styles/App.css';
// CSS is now loaded asynchronously in index.js
// import './styles/App.css';

// Layout Components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import GTMBody from './components/common/GTMBody';

// Main Pages - Load immediately
import Home from './pages/Home';
import NotFound from './pages/NotFound';

// Create a loading component
const Loading = () => (
  <div className="loading-container">
    <div className="loading-spinner"></div>
  </div>
);

// Lazy load all other pages
const UnitConverters = lazy(() => import('./pages/UnitConverters'));
const FinancialCalculators = lazy(() => import('./pages/FinancialCalculators'));
const AdditionalTools = lazy(() => import('./pages/AdditionalTools'));
const HealthAndFitness = lazy(() => import('./pages/health_fitness/HealthAndFitness'));
const Blogs = lazy(() => import('./pages/Blogs'));
const Educational = lazy(() => import('./pages/Educational'));
const Contact = lazy(() => import('./pages/Contact'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const AboutUs = lazy(() => import('./pages/AboutUs'));

// Unit Converters - Lazy loaded
const TemperatureConverter = lazy(() => import('./pages/converters/TemperatureConverter'));
const LengthConverter = lazy(() => import('./pages/converters/LengthConverter'));
const WeightConverter = lazy(() => import('./pages/converters/WeightConverter'));
const AreaConverter = lazy(() => import('./pages/converters/AreaConverter'));
const VolumeConverter = lazy(() => import('./pages/converters/VolumeConverter'));
const TimeConverter = lazy(() => import('./pages/converters/TimeConverter'));

// Financial Calculators - Lazy loaded
const SipCalculator = lazy(() => import('./pages/calculators/SipCalculator'));
const LumpsumCalculator = lazy(() => import('./pages/calculators/LumpsumCalculator'));
const HomeLoanEmiCalculator = lazy(() => import('./pages/calculators/HomeLoanEmiCalculator'));
const MortgageCalculator = lazy(() => import('./pages/calculators/MortgageCalculator'));
const FdWithTaxCalculator = lazy(() => import('./pages/calculators/FdWithTaxCalculator'));
const RentalYieldCalculator = lazy(() => import('./pages/calculators/RentalYieldCalculator'));
const IncomeTaxCalculator = lazy(() => import('./pages/calculators/IncomeTaxCalculator'));
const RentVsBuyCalculator = lazy(() => import('./pages/calculators/RentVsBuyCalculator'));
const NPSCalculator = lazy(() => import('./pages/calculators/NPSCalculator'));
const GSTCalculator = lazy(() => import('./pages/calculators/GSTCalculator'));
const GratuityCalculator = lazy(() => import('./pages/calculators/GratuityCalculator'));
const HRAExemptionCalculator = lazy(() => import('./pages/calculators/HRAExemptionCalculator'));
const EPSPensionCalculator = lazy(() => import('./pages/calculators/EPSPensionCalculator'));
const UKVatCalculator = lazy(() => import('./pages/calculators/UKVatCalculator'));

// Health & Fitness Tools - Lazy loaded
const BmiCalculator = lazy(() => import('./pages/tools/BmiCalculator'));
const BmrCalculator = lazy(() => import('./pages/tools/BmrCalculator'));
const TDEECalculator = lazy(() => import('./pages/calculators/TDEECalculator'));
const MacroCalculator = lazy(() => import('./pages/calculators/MacroCalculator'));
const BodyFatCalculator = lazy(() => import('./pages/calculators/BodyFatCalculator'));

// Additional Tools - Lazy loaded
const PasswordGenerator = lazy(() => import('./pages/tools/PasswordGenerator'));
const TimeTeller = lazy(() => import('./pages/tools/TimeTeller'));
const JsonValidator = lazy(() => import('./pages/tools/JsonValidator'));
const ImageConverter = lazy(() => import('./pages/tools/ImageConverter'));
const Base64Tool = lazy(() => import('./pages/tools/Base64Tool'));

// Blog Posts - Lazy loaded
const InflationImpactOnInvestments = lazy(() => import('./pages/blogs/InflationImpactOnInvestments'));
const SipVsLumpsum = lazy(() => import('./pages/blogs/SipVsLumpsum'));
const RealEstateInvestmentBeginnersGuide = lazy(() => import('./pages/blogs/RealEstateInvestmentBeginnersGuide'));
const CryptocurrencyInvestmentProsCons = lazy(() => import('./pages/blogs/CryptocurrencyInvestmentProsCons'));

// Educational Resources - Lazy loaded
const InvestmentBasics = lazy(() => import('./pages/educational/InvestmentBasics'));
const FinancialPlanning = lazy(() => import('./pages/educational/FinancialPlanning'));
const MarketAnalysis = lazy(() => import('./pages/educational/MarketAnalysis'));

function App() {
  return (
    <div className="app">
      <GTMBody />
      <Header />
      <main className="main-content">
        <Suspense fallback={<Loading />}>
          <Routes>
            {/* Main Pages */}
            <Route path="/" element={<Home />} />
            <Route path="/unit_converters" element={<UnitConverters />} />
            <Route path="/financial_calculators" element={<FinancialCalculators />} />
            <Route path="/health_fitness" element={<HealthAndFitness />} />
            <Route path="/additional_tools" element={<AdditionalTools />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/educational" element={<Educational />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/about" element={<AboutUs />} />

            {/* Financial Calculators */}
            <Route path="/sip_calculator" element={<SipCalculator />} />
            <Route path="/lumpsum_calculator" element={<LumpsumCalculator />} />
            <Route path="/home_loan_emi_calculator" element={<HomeLoanEmiCalculator />} />
            <Route path="/mortgage_calculator" element={<MortgageCalculator />} />
            <Route path="/fd_with_tax" element={<FdWithTaxCalculator />} />
            <Route path="/rental_yield" element={<RentalYieldCalculator />} />
            <Route path="/income_tax_calculator" element={<IncomeTaxCalculator />} />
            <Route path="/rent_vs_buy_calculator" element={<RentVsBuyCalculator />} />
            <Route path="/nps_calculator" element={<NPSCalculator />} />
            <Route path="/gst_calculator" element={<GSTCalculator />} />
            <Route path="/gratuity_calculator" element={<GratuityCalculator />} />
            <Route path="/hra_exemption_calculator" element={<HRAExemptionCalculator />} />
            <Route path="/eps_pension_calculator" element={<EPSPensionCalculator />} />
            <Route path="/uk_vat_calculator" element={<UKVatCalculator />} />
            
            {/* Unit Converters */}
            <Route path="/temperature_conversion" element={<TemperatureConverter />} />
            <Route path="/length_conversion" element={<LengthConverter />} />
            <Route path="/weight_conversion" element={<WeightConverter />} />
            <Route path="/area_conversion" element={<AreaConverter />} />
            <Route path="/volume_conversion" element={<VolumeConverter />} />
            <Route path="/time_conversion" element={<TimeConverter />} />
            
            {/* Health & Fitness Tools */}
            <Route path="/bmi_calculator" element={<BmiCalculator />} />
            <Route path="/bmr_calculator" element={<BmrCalculator />} />
            <Route path="/tdee_calculator" element={<TDEECalculator />} />
            <Route path="/macro_calculator" element={<MacroCalculator />} />
            <Route path="/body_fat_calculator" element={<BodyFatCalculator />} />
            
            {/* Additional Tools */}
            <Route path="/password_generator" element={<PasswordGenerator />} />
            <Route path="/time_teller" element={<TimeTeller />} />
            <Route path="/json_validator" element={<JsonValidator />} />
            <Route path="/image_converter" element={<ImageConverter />} />
            <Route path="/base64_tool" element={<Base64Tool />} />
            
            {/* Blog Posts */}
            <Route path="/blogs/inflation-impact-on-investments" element={<InflationImpactOnInvestments />} />
            <Route path="/blogs/sip-vs-lumpsum" element={<SipVsLumpsum />} />
            <Route path="/blogs/real-estate-investment-beginners-guide" element={<RealEstateInvestmentBeginnersGuide />} />
            <Route path="/blogs/cryptocurrency-investment-pros-cons" element={<CryptocurrencyInvestmentProsCons />} />
            
            {/* Educational Resources */}
            <Route path="/educational/investment-basics" element={<InvestmentBasics />} />
            <Route path="/educational/financial-planning" element={<FinancialPlanning />} />
            <Route path="/educational/market-analysis" element={<MarketAnalysis />} />
            
            {/* 404 Page - Catch all unmatched routes */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}

export default App; 