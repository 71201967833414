import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ 
  title, 
  description, 
  keywords, 
  canonicalUrl, 
  ogImage = 'https://thedailytools.com/images/default-preview.jpg',
  ogType = 'website',
  twitterCard = 'summary_large_image',
  schemaType = 'WebApplication',
  schemaName,
  schemaDescription,
  schemaCategory = 'FinanceApplication',
  schemaFeatures = [],
  children
}) => {
  // Default values
  const defaultTitle = 'The Daily Tools | Free Online Calculators and Converters';
  const defaultDescription = 'Free online calculators and converters for everyday use. Financial calculators, unit converters, and more.';
  const defaultKeywords = 'calculators, converters, tools, online tools, financial calculators, unit converters';
  const siteUrl = 'https://thedailytools.com';
  
  // Use provided values or defaults
  const metaTitle = title || defaultTitle;
  const metaDescription = description || defaultDescription;
  const metaKeywords = keywords || defaultKeywords;
  const metaCanonicalUrl = canonicalUrl ? `${siteUrl}${canonicalUrl}` : siteUrl;
  const metaSchemaName = schemaName || metaTitle;
  const metaSchemaDescription = schemaDescription || metaDescription;

  // Schema markup
  const schemaMarkup = {
    '@context': 'http://schema.org',
    '@type': schemaType,
    'name': metaSchemaName,
    'description': metaSchemaDescription,
    'url': metaCanonicalUrl,
    'applicationCategory': schemaCategory,
    'operatingSystem': 'Any',
    'offers': {
      '@type': 'Offer',
      'price': '0',
      'priceCurrency': 'USD'
    },
    'featureList': schemaFeatures,
    'screenshot': ogImage,
    'author': {
      '@type': 'Organization',
      'name': 'The Daily Tools',
      'url': siteUrl
    }
  };

  // Load GTM script after page load
  React.useEffect(() => {
    // Only load GTM in production
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KS34VG7F');
      `;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="author" content="The Daily Tools" />
      <meta name="robots" content="index, follow" />
      
      {/* Canonical URL */}
      <link rel="canonical" href={metaCanonicalUrl} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={metaCanonicalUrl} />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={ogImage} />
      
      {/* Schema.org Markup */}
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
      
      {children}
    </Helmet>
  );
};

export default SEO; 