import React from 'react';
import { Link } from 'react-router-dom';
import { FaCompass, FaHome } from 'react-icons/fa';
import SEO from '../components/common/SEO';

const NotFound = () => {
  return (
    <>
      <SEO 
        title="Page Not Found | The Daily Tools"
        description="Oops! Looks like you've wandered into uncharted territory."
      />
      <div className="container">
        <div className="not-found-container">
          <div className="not-found-icon">
            <FaCompass />
          </div>
          <h1>Are you lost in calculation?</h1>
          <p>Seems like you've wandered into the mathematical wilderness. Even our best calculators couldn't compute this page!</p>
          <p className="not-found-subtitle">The page you're looking for has either been moved, deleted, or never existed in the first dimension.</p>
          <Link to="/" className="home-button">
            <FaHome /> Take Me Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound; 