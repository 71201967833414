import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Prevent scrolling when mobile menu is open
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [mobileMenuOpen]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header-content">
          <Link to="/" className="logo">
            <h1>The Daily Tools</h1>
          </Link>
          
          {mobileMenuOpen && (
            <div className="menu-overlay" onClick={() => setMobileMenuOpen(false)}></div>
          )}
          
          <nav className={`main-nav ${mobileMenuOpen ? 'active' : ''}`}>
            <ul>
              <li><Link to="/" onClick={() => setMobileMenuOpen(false)}>Home</Link></li>
              <li><Link to="/unit_converters" onClick={() => setMobileMenuOpen(false)}>Unit Converters</Link></li>
              <li><Link to="/financial_calculators" onClick={() => setMobileMenuOpen(false)}>Financial Calculators</Link></li>
              <li><Link to="/health_fitness" onClick={() => setMobileMenuOpen(false)}>Health & Fitness</Link></li>
              <li><Link to="/additional_tools" onClick={() => setMobileMenuOpen(false)}>Additional Tools</Link></li>
              <li><Link to="/blogs" onClick={() => setMobileMenuOpen(false)}>Blogs</Link></li>
            </ul>
          </nav>
          
          <button 
            className={`mobile-menu-toggle ${mobileMenuOpen ? 'active' : ''}`}
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header; 