import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <h3>The Daily Tools</h3>
            <p>Free online calculators and converters for everyday use</p>
          </div>
          <div className="footer-links">
            <div className="footer-section">
              <h4>Tools</h4>
              <ul>
                <li><Link to="/unit_converters">Unit Converters</Link></li>
                <li><Link to="/financial_calculators">Financial Calculators</Link></li>
                <li><Link to="/health_fitness">Health & Fitness</Link></li>
                <li><Link to="/additional_tools">Additional Tools</Link></li>
              </ul>
            </div>
            <div className="footer-section">
              <h4>Resources</h4>
              <ul>
                <li><Link to="/blogs">Blog</Link></li>
                <li><Link to="/educational">Educational Resources</Link></li>
              </ul>
            </div>
            <div className="footer-section">
              <h4>About</h4>
              <ul>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/terms-of-service">Terms of Service</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {currentYear} The Daily Tools. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 