import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/common/SEO';
import { 
  FaThermometerHalf, 
  FaRuler, 
  FaWeight, 
  FaVectorSquare, 
  FaCube, 
  FaClock,
  FaChartLine,
  FaMoneyBillWave,
  FaHome,
  FaBuilding,
  FaPercentage,
  FaCity,
  FaCalculator,
  FaHeartbeat,
  FaFireAlt,
  FaKey,
  FaGlobeAmericas,
  FaCode,
  FaImage,
  FaDumbbell,
  FaRunning,
  FaAppleAlt,
  FaBalanceScale,
  FaChartBar,
  FaUserShield,
  FaReceipt,
  FaHandHoldingUsd,
  FaHouseUser,
  FaRegCreditCard,
  FaUniversity
} from 'react-icons/fa';

const Home = () => {
  const categories = [
    {
      id: 'financial_calculators',
      title: 'Financial Calculators',
      description: 'Calculate financial metrics and investments',
      link: '/financial_calculators',
      tools: [
        { 
          name: 'SIP Calculator', 
          link: '/sip_calculator',
          icon: <FaChartLine className="w-6 h-6 text-primary" />,
          description: 'Calculate returns on systematic investments'
        },
        { 
          name: 'Lumpsum Calculator', 
          link: '/lumpsum_calculator',
          icon: <FaMoneyBillWave className="w-6 h-6 text-secondary" />,
          description: 'Calculate returns on one-time investments'
        },
        { 
          name: 'Home Loan EMI Calculator', 
          link: '/home_loan_emi_calculator',
          icon: <FaHome className="w-6 h-6 text-primary" />,
          description: 'Calculate EMI for your home loan'
        },
        { 
          name: 'Mortgage Calculator', 
          link: '/mortgage_calculator',
          icon: <FaBuilding className="w-6 h-6 text-secondary" />,
          description: 'Calculate mortgage payments and amortization'
        },
        { 
          name: 'FD with Tax Calculator', 
          link: '/fd_with_tax',
          icon: <FaPercentage className="w-6 h-6 text-primary" />,
          description: 'Calculate fixed deposit returns with tax'
        },
        { 
          name: 'Rental Yield Calculator', 
          link: '/rental_yield',
          icon: <FaCity className="w-6 h-6 text-secondary" />,
          description: 'Calculate returns on rental property investments'
        },
        { 
          name: 'Income Tax Calculator', 
          link: '/income_tax_calculator',
          icon: <FaCalculator className="w-6 h-6 text-primary" />,
          description: 'Calculate income tax under old and new regimes'
        },
        {
          name: 'GST Calculator',
          link: '/gst_calculator',
          icon: <FaReceipt className="w-6 h-6 text-secondary" />,
          description: 'Calculate GST inclusive and exclusive amounts'
        },
        {
          name: 'Gratuity Calculator',
          link: '/gratuity_calculator',
          icon: <FaHandHoldingUsd className="w-6 h-6 text-primary" />,
          description: 'Calculate gratuity based on service duration'
        },
        {
          name: 'HRA Exemption Calculator',
          link: '/hra_exemption_calculator',
          icon: <FaHouseUser className="w-6 h-6 text-secondary" />,
          description: 'Calculate tax exemption on House Rent Allowance'
        },
        {
          name: 'EPS Pension Calculator',
          link: '/eps_pension_calculator',
          icon: <FaUniversity className="w-6 h-6 text-primary" />,
          description: 'Compare regular and higher pension schemes'
        },
        {
          name: 'UK VAT Calculator',
          link: '/uk_vat_calculator',
          icon: <FaRegCreditCard className="w-6 h-6 text-secondary" />,
          description: 'Calculate VAT for UK transactions at different rates'
        },
        {
          name: 'Rent vs Buy Calculator',
          link: '/rent_vs_buy_calculator',
          icon: <FaBalanceScale className="w-6 h-6 text-secondary" />,
          description: 'Compare the financial implications of renting versus buying a home'
        },
        {
          name: 'NPS Calculator',
          link: '/nps_calculator',
          icon: <FaUserShield className="w-6 h-6 text-primary" />,
          description: 'Plan your retirement with National Pension System projections'
        }
      ]
    },
    {
      id: 'health_fitness',
      title: 'Health & Fitness',
      description: 'Track and calculate your health metrics',
      link: '/health_fitness',
      tools: [
        { 
          name: 'BMI Calculator', 
          link: '/bmi_calculator',
          icon: <FaHeartbeat className="w-6 h-6 text-primary" />,
          description: 'Calculate your Body Mass Index'
        },
        { 
          name: 'BMR Calculator', 
          link: '/bmr_calculator',
          icon: <FaFireAlt className="w-6 h-6 text-secondary" />,
          description: 'Calculate your Basal Metabolic Rate'
        },
        { 
          name: 'TDEE Calculator', 
          link: '/tdee_calculator',
          icon: <FaDumbbell className="w-6 h-6 text-primary" />,
          description: 'Calculate your Total Daily Energy Expenditure'
        },
        { 
          name: 'Macro Calculator', 
          link: '/macro_calculator',
          icon: <FaAppleAlt className="w-6 h-6 text-secondary" />,
          description: 'Calculate your daily macronutrient needs'
        },
        { 
          name: 'Body Fat Calculator', 
          link: '/body_fat_calculator',
          icon: <FaRunning className="w-6 h-6 text-primary" />,
          description: 'Estimate your body fat percentage'
        }
      ]
    },
    {
      id: 'unit_converters',
      title: 'Unit Converters',
      description: 'Convert between different units of measurement',
      link: '/unit_converters',
      tools: [
        { 
          name: 'Temperature Converter', 
          link: '/temperature_conversion',
          icon: <FaThermometerHalf className="w-6 h-6 text-primary" />,
          description: 'Convert between Celsius, Fahrenheit, and Kelvin'
        },
        { 
          name: 'Length Converter', 
          link: '/length_conversion',
          icon: <FaRuler className="w-6 h-6 text-secondary" />,
          description: 'Convert between meters, feet, inches, and more'
        },
        { 
          name: 'Weight Converter', 
          link: '/weight_conversion',
          icon: <FaWeight className="w-6 h-6 text-primary" />,
          description: 'Convert between kilograms, pounds, ounces, and more'
        },
        { 
          name: 'Area Converter', 
          link: '/area_conversion',
          icon: <FaVectorSquare className="w-6 h-6 text-secondary" />,
          description: 'Convert between square meters, acres, and more'
        },
        { 
          name: 'Volume Converter', 
          link: '/volume_conversion',
          icon: <FaCube className="w-6 h-6 text-primary" />,
          description: 'Convert between liters, gallons, cubic meters, and more'
        },
        { 
          name: 'Time Converter', 
          link: '/time_conversion',
          icon: <FaClock className="w-6 h-6 text-secondary" />,
          description: 'Convert between seconds, minutes, hours, and days'
        },
      ]
    },
    {
      id: 'additional_tools',
      title: 'Additional Tools',
      description: 'Other useful tools for everyday use',
      link: '/additional_tools',
      tools: [
        { 
          name: 'Password Generator', 
          link: '/password_generator',
          icon: <FaKey className="w-6 h-6 text-primary" />,
          description: 'Generate secure random passwords'
        },
        { 
          name: 'Time Teller', 
          link: '/time_teller',
          icon: <FaGlobeAmericas className="w-6 h-6 text-secondary" />,
          description: 'Check current time in different time zones'
        },
        { 
          name: 'JSON Validator', 
          link: '/json_validator',
          icon: <FaCode className="w-6 h-6 text-primary" />,
          description: 'Validate, format, and beautify JSON data'
        },
        {
          name: 'Image Converter',
          link: '/image_converter',
          icon: <FaImage className="w-6 h-6 text-secondary" />,
          description: 'Convert images between formats'
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-background py-12 px-4 sm:px-6 lg:px-8">
      <SEO 
        title="The Daily Tools | Free Online Calculators and Converters"
        description="Free online calculators and converters for everyday use. Financial calculators, health & fitness tools, unit converters, and more tools to simplify your daily tasks."
        keywords="calculators, converters, tools, online tools, financial calculators, health calculators, fitness calculators, unit converters, daily tools, free tools"
        canonicalUrl="/"
        ogImage="https://thedailytools.com/images/home-preview.jpg"
        schemaType="WebSite"
        schemaName="The Daily Tools"
        schemaDescription="Free online calculators and converters for everyday use. Financial calculators, health & fitness tools, unit converters, and more tools to simplify your daily tasks."
        schemaFeatures={[
          "Financial Calculators",
          "Health & Fitness Tools",
          "Unit Converters",
          "Utility Tools"
        ]}
      />
      <div className="max-w-7xl mx-auto">
        <section className="bg-gradient-to-r from-primary to-secondary rounded-2xl p-8 mb-12 text-white text-center">
          <h1 className="text-4xl md:text-5xl font-display font-bold mb-4">The Daily Tools</h1>
          <p className="text-xl opacity-90 max-w-3xl mx-auto">Free online calculators and converters for everyday use</p>
        </section>

        <section className="space-y-16">
          {categories.map((category) => (
            <div key={category.id} className="mb-16">
              <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                <Link to={category.link} className="text-2xl md:text-3xl font-display font-bold text-primary mb-2 md:mb-0 hover:text-primary-dark transition-colors">
                  {category.title}
                </Link>
                <Link to={category.link} className="text-primary font-semibold hover:text-primary-dark hover:underline transition-colors">
                  View All
                </Link>
              </div>
              <p className="text-gray-600 mb-6">{category.description}</p>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {category.tools.map((tool) => (
                  <Link 
                    key={tool.link} 
                    to={tool.link}
                    className="bg-white rounded-2xl shadow-card p-6 flex flex-col hover:shadow-lg transition-all duration-300 h-full group"
                  >
                    <div className="flex items-start space-x-4 mb-4">
                      <div className="p-3 rounded-xl bg-background">
                        {tool.icon}
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-2 group-hover:text-primary transition-colors">
                          {tool.name}
                        </h3>
                        <p className="text-gray-600 text-sm">
                          {tool.description}
                        </p>
                      </div>
                    </div>
                    <div className="mt-auto pt-4">
                      <div className="inline-flex items-center justify-center w-full px-6 py-3 rounded-xl font-semibold bg-primary text-white group-hover:bg-primary-dark transition-all duration-200">
                        Open Tool
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </section>

        {/* Featured Blog Posts */}
        <section className="mt-24 mb-16">
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <h2 className="text-2xl md:text-3xl font-display font-bold text-primary mb-2 md:mb-0">Resources & Articles</h2>
            <div className="flex space-x-4">
              <Link to="/blogs" className="text-primary font-semibold hover:text-primary-dark hover:underline transition-colors">
                View All Articles
              </Link>
              <Link to="/educational" className="text-secondary font-semibold hover:text-secondary-dark hover:underline transition-colors">
                Educational Resources
              </Link>
            </div>
          </div>
          <p className="text-gray-600 mb-8">Expert insights and educational content to help you make informed financial decisions</p>
          
          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6">
            {/* Featured Blog Post */}
            <Link 
              to="/blogs/inflation-impact-on-investments"
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group flex flex-col overflow-hidden"
            >
              <div className="relative">
                <img 
                  src="https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?auto=format&fit=crop&q=80&w=1000" 
                  alt="Inflation and Investments"
                  className="w-full h-40 object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute top-2 left-2">
                  <span className="inline-block px-2 py-1 rounded-lg bg-primary text-white text-xs font-semibold">
                    Financial Education
                  </span>
                </div>
              </div>
              <div className="p-4 flex flex-col flex-grow">
                <div className="flex items-center text-xs text-gray-500 mb-2">
                  <span>June 10, 2023</span>
                  <span className="mx-2">•</span>
                  <span>12 min read</span>
                </div>
                <h3 className="text-sm font-bold mb-2 group-hover:text-primary transition-colors line-clamp-2">
                  Inflation and Its Impact on Investments
                </h3>
                <div className="mt-auto pt-2 text-xs inline-flex items-center font-semibold text-primary group-hover:text-primary-dark transition-colors">
                  Read Article <span className="ml-1">→</span>
                </div>
              </div>
            </Link>
            
            <Link 
              to="/blogs/sip-vs-lumpsum"
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group flex flex-col overflow-hidden"
            >
              <div className="relative">
                <img 
                  src="https://images.unsplash.com/photo-1579532537598-459ecdaf39cc?auto=format&fit=crop&q=80&w=1000" 
                  alt="SIP vs Lumpsum"
                  className="w-full h-40 object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute top-2 left-2">
                  <span className="inline-block px-2 py-1 rounded-lg bg-secondary text-white text-xs font-semibold">
                    Investment Strategy
                  </span>
                </div>
              </div>
              <div className="p-4 flex flex-col flex-grow">
                <div className="flex items-center text-xs text-gray-500 mb-2">
                  <span>May 25, 2023</span>
                  <span className="mx-2">•</span>
                  <span>10 min read</span>
                </div>
                <h3 className="text-sm font-bold mb-2 group-hover:text-primary transition-colors line-clamp-2">
                  SIP vs Lumpsum: Which Strategy Works Better?
                </h3>
                <div className="mt-auto pt-2 text-xs inline-flex items-center font-semibold text-primary group-hover:text-primary-dark transition-colors">
                  Read Article <span className="ml-1">→</span>
                </div>
              </div>
            </Link>
            
            <Link 
              to="/blogs/real-estate-investment-beginners-guide"
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group flex flex-col overflow-hidden"
            >
              <div className="relative">
                <img 
                  src="https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&q=80&w=1000" 
                  alt="Real Estate Investment"
                  className="w-full h-40 object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute top-2 left-2">
                  <span className="inline-block px-2 py-1 rounded-lg bg-primary text-white text-xs font-semibold">
                    Real Estate
                  </span>
                </div>
              </div>
              <div className="p-4 flex flex-col flex-grow">
                <div className="flex items-center text-xs text-gray-500 mb-2">
                  <span>April 18, 2023</span>
                  <span className="mx-2">•</span>
                  <span>15 min read</span>
                </div>
                <h3 className="text-sm font-bold mb-2 group-hover:text-primary transition-colors line-clamp-2">
                  Real Estate Investment: A Beginner's Guide
                </h3>
                <div className="mt-auto pt-2 text-xs inline-flex items-center font-semibold text-primary group-hover:text-primary-dark transition-colors">
                  Read Article <span className="ml-1">→</span>
                </div>
              </div>
            </Link>
            
            <Link 
              to="/blogs/cryptocurrency-investment-pros-cons"
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group flex flex-col overflow-hidden"
            >
              <div className="relative">
                <img 
                  src="https://images.unsplash.com/photo-1518546305927-5a555bb7020d?auto=format&fit=crop&q=80&w=1000" 
                  alt="Cryptocurrency Investment"
                  className="w-full h-40 object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute top-2 left-2">
                  <span className="inline-block px-2 py-1 rounded-lg bg-secondary text-white text-xs font-semibold">
                    Digital Assets
                  </span>
                </div>
              </div>
              <div className="p-4 flex flex-col flex-grow">
                <div className="flex items-center text-xs text-gray-500 mb-2">
                  <span>March 5, 2023</span>
                  <span className="mx-2">•</span>
                  <span>14 min read</span>
                </div>
                <h3 className="text-sm font-bold mb-2 group-hover:text-primary transition-colors line-clamp-2">
                  Cryptocurrency Investment: Pros and Cons
                </h3>
                <div className="mt-auto pt-2 text-xs inline-flex items-center font-semibold text-primary group-hover:text-primary-dark transition-colors">
                  Read Article <span className="ml-1">→</span>
                </div>
              </div>
            </Link>
          </div>
          
          {/* Educational Resources */}
          <div className="mt-10">
            <h3 className="text-xl font-bold mb-6">Educational Resources</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <Link to="/educational/investment-basics" className="bg-white rounded-xl shadow-sm p-4 hover:shadow-md transition-all duration-300 group flex items-start">
                <div className="bg-primary/10 p-2 rounded-lg mr-3">
                  <FaMoneyBillWave className="w-5 h-5 text-primary" />
                </div>
                <div>
                  <h4 className="text-sm font-bold group-hover:text-primary transition-colors mb-1">Investment Basics</h4>
                  <p className="text-xs text-gray-600 line-clamp-2">Learn the fundamentals of investing, including different asset classes and risk management.</p>
                </div>
              </Link>
              
              <Link to="/educational/financial-planning" className="bg-white rounded-xl shadow-sm p-4 hover:shadow-md transition-all duration-300 group flex items-start">
                <div className="bg-secondary/10 p-2 rounded-lg mr-3">
                  <FaChartBar className="w-5 h-5 text-secondary" />
                </div>
                <div>
                  <h4 className="text-sm font-bold group-hover:text-primary transition-colors mb-1">Financial Planning</h4>
                  <p className="text-xs text-gray-600 line-clamp-2">Understand the process of creating a comprehensive financial plan for your goals.</p>
                </div>
              </Link>
              
              <Link to="/educational/market-analysis" className="bg-white rounded-xl shadow-sm p-4 hover:shadow-md transition-all duration-300 group flex items-start">
                <div className="bg-primary/10 p-2 rounded-lg mr-3">
                  <FaChartLine className="w-5 h-5 text-primary" />
                </div>
                <div>
                  <h4 className="text-sm font-bold group-hover:text-primary transition-colors mb-1">Market Analysis</h4>
                  <p className="text-xs text-gray-600 line-clamp-2">Explore approaches to analyze financial markets and economic indicators.</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home; 