import React from 'react';

const GTMBody = () => {
  return (
    <noscript>
      <iframe 
        src="https://www.googletagmanager.com/ns.html?id=GTM-KS34VG7F"
        height="0" 
        width="0" 
        style={{ display: 'none', visibility: 'hidden' }}
        title="Google Tag Manager"
      />
    </noscript>
  );
};

export default GTMBody; 